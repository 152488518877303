import 'core-js/fn/object/assign';
import 'core-js/es6/promise';
import loadCSS from './common/utils/load-css';

/*eslint-disable piggyback/no-restricted-global-extend*/
window.loadCSS = loadCSS;

const browsers = {
	mobile: (navigator.userAgent.match(/mobi/i)),
	firefox: navigator.userAgent.indexOf('Firefox') > -1,
	webKit: !!navigator.userAgent.match(/webkit/i),
	chrome: navigator.userAgent.indexOf('Chrome') > -1,
	safari: navigator.userAgent.indexOf('Safari') > -1,
	opera: !!window.opera || /opera|opr/i.test(navigator.userAgent),
	android: navigator.userAgent.indexOf('Android') > -1,
	iOs: (!!navigator.userAgent.match(/iPad|iPhone|iPod/g)) && !window.MSStream,
	ie: navigator.userAgent.indexOf('Trident/') > -1,
	edge: navigator.userAgent.indexOf('Edge/') > -1
};

browsers.webKit = !browsers.edge && browsers.webKit;
browsers.chrome = !browsers.edge && browsers.chrome;
browsers.safari = !browsers.edge && browsers.safari && !browsers.chrome;
browsers.ms = browsers.ie || browsers.edge;
let htmlClasses = '';
for (const name in browsers) {
	if (browsers.hasOwnProperty(name) && browsers[name]) {
		htmlClasses += ' ' + name;
	}
}
document.querySelector('html').className += htmlClasses;


const Modernizr = window.Modernizr;
// adapted from this one: https://codepen.io/shshaw/pen/yyOaqW
window.Modernizr.addTest('clippath', () => {
	const value = 'polygon(50% 0%, 0% 100%, 100% 100%)';
	// Try using window.CSS.supports
	if ('CSS' in window && 'supports' in window.CSS) {
		for (let i = 0; i < Modernizr._prefixes.length; i++) {
			const prop = Modernizr._prefixes[i] + 'clip-path';
			if (window.CSS.supports(prop, value)) {
				return true;
			}
		}
		return false;
	}
	// Otherwise, use Modernizr.testStyles and examine the property manually
	if(!Modernizr.testStyles) return false;
	return Modernizr.testStyles('#modernizr { '+ Modernizr._prefixes.join('clip-path:' + value +'; ')+' }', (elem, rule) => {
		const style = getComputedStyle(elem);
		let clip = style.clipPath;
		if (!clip || clip === 'none') {
			clip = false;
			for (let i = 0; i < Modernizr._domPrefixes.length; i++) {
				const test = Modernizr._domPrefixes[i] + 'ClipPath';
				if (style[test] && style[test] !== 'none') {
					clip = true;
				break;
				}
			}
		}
	return Modernizr.testProp('clipPath') && clip;
});
});
